import { HttpClient } from '@angular/common/http'
import { Injectable, signal } from '@angular/core'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { catchError, lastValueFrom, map, Observable, of } from 'rxjs'
import { endpoints } from '../../../environments/endpoints'
import { User, UserLoginResponse } from '../model/User'
import { NotificationService } from './notification.service'

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	isAuthenticated = signal<boolean>(false)
	isAdmin = signal<boolean>(false)
	userId = signal<string>('')
	email = signal<string>('')
	permissionsChecked = signal<boolean>(false)

	constructor(
		private http: HttpClient, 
		private router: Router,
		private notificationService: NotificationService
	) {}

	async login(email: string, password: string) {
		const body = {
			email: email,
			password: password,
		}

		try {
			const loginRespinse = await lastValueFrom(
				this.http.post<UserLoginResponse>(endpoints.login, body, {
					withCredentials: true,
				})
			)

			if (loginRespinse) {
				this.notificationService.openSnackBar('Sikeres bejelentkezés')
				this.userId.set(loginRespinse.id)
				this.isAdmin.set(loginRespinse.isAdmin)
				this.isAuthenticated.set(true)
				this.email.set(loginRespinse.email)

				this.router.navigate(['/main'])
			}
		} catch (error: any) {
			this.notificationService.openSnackBar(`Sikertelen bejelentkezés: ${error.error.message}`)
			console.log(error)
		}
	}

	register(user: User) {
		const body = new URLSearchParams()
		body.set('email', user.email)
		body.set('name', user.name)

		return this.http.post(endpoints.register, body)
	}

	logout() {
		this.isAdmin.set(false)
		this.isAuthenticated.set(false)
		this.userId.set('')
		this.email.set('')
		return this.http.post(
			endpoints.logout,
			{},
			{ withCredentials: true, responseType: 'text' }
		)
	}

	// Not used currently
	checkPermissions(route: ActivatedRouteSnapshot): Observable<boolean> {
		console.log(`Setting user status (permissionsChecked: ${this.permissionsChecked()})`)

		if (this.permissionsChecked()) {
			console.warn('Permissions already checked')

			if (route.data['roles'][0] == 'admin') {
				console.warn('Site requests admin access')
				if (this.isAdmin()) {
					console.warn('User is admin')
					return of(true)
				} else {
					console.warn('User is not admin')
					this.router.navigateByUrl('/login')
					return of(false)
				}
			}
			console.error('Not admin access is needed?')
			return of(false)
		}

		console.log('Checking permissions');
		
		return this.checkPermissionsFromServer().pipe(
			map((object: any) => {
				if (!object.isAuthenticated) {
					this.isAuthenticated.set(false)
					this.isAdmin.set(false)
					this.userId.set('')
					this.email.set('')
					this.permissionsChecked.set(false)
					console.log('User is not authenticated')
					this.router.navigateByUrl('/login')
					return false
				} else {
					console.log('User is authenticated')
					this.isAuthenticated.set(true)
					if (route.data['roles'][0] == 'admin') {
						console.log('Site requests admin access')
						if (object.isAdmin) {
							console.log('User is admin')
							this.permissionsChecked.set(true)
							this.isAdmin.set(true)
							return true
						} else {
							console.log('User is not admin')
							this.router.navigateByUrl('/main')
							this.permissionsChecked.set(true)
							this.isAdmin.set(false)
							return false
						}
					}
					return false
				}
			}),

			catchError((error) => {
				console.log(error)
				this.router.navigateByUrl('/login')
				this.isAdmin.set(false)
				this.isAuthenticated.set(false)
				this.userId.set('')
				this.permissionsChecked.set(false)
				return of(false)
			})
		)
	}

	checkPermissionsFromServer() {
		console.log('Checking auth')

		return this.http.get<boolean>(endpoints.checkPermissions, {
			withCredentials: true,
		})
	}

	checkAdmin() {
		console.log('Checking admin')
		return this.http.get<boolean>(endpoints.checkAdmin, {
			withCredentials: true,
		})
	}
}
